<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">

    <h1>Hola mundo</h1>

    <button @click="goToDaybook" class="btn btn-primary">Primary</button>
    <button class="btn btn-secondary">Secondary</button>
    <button class="btn btn-success">Success</button>
  </div>
</template>

<script>

import router from '@/router';

export default {
  methods: {
    goToDaybook() {
      router.push({ name: 'no-entry' })
    }

  }
}

</script>
