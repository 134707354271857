import axios from "axios";

const journalApi = axios.create({


    baseURL: 'https://vue-demos-b653f-default-rtdb.firebaseio.com'


})

export default journalApi